import React, { useState } from "react"
import { addItemToFirestore } from "../../utils/add-item.js"
const AddCrop = () => {
  const [showAddType, setShowAddType] = useState(false)

  const [newType, setNewType] = useState("")
  const [newVarieties, setNewVarieties] = useState("")

  const toggleAddType = () => {
    setShowAddType(!showAddType)
  }

  const handleAddVariety = event => {
    event.preventDefault()

    addItemToFirestore("crop", {
      Type: newType,
      Variety: newVarieties.split(","),
    })

    setNewType("")
    setNewVarieties("")
    setShowAddType(false)
  }

  return (
    <div>
      <button type="button" id="add-type-button" onClick={toggleAddType}>
        + Add a type of crop
      </button>
      {showAddType && (
        <form onSubmit={handleAddVariety}>
          <legend>Add a type of crop</legend>
          <div>
            <label>
              <strong>Type: </strong>
              <input
                type="text"
                value={newType}
                onChange={e => setNewType(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label>
              <strong>Varieties: </strong>
              <input
                type="text"
                value={newVarieties}
                onChange={e => setNewVarieties(e.target.value)}
              />
              <span>Comma seperated list</span>
            </label>
          </div>
          <button type="submit" id="submit-button">
            Add
          </button>{" "}
          |{" "}
          <button type="button" id="cancel-button" onClick={toggleAddType}>
            Cancel
          </button>
        </form>
      )}
    </div>
  )
}

export default AddCrop
