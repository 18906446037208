import React, { useState, useEffect } from "react"
import { addItemToFirestore } from "../../utils/add-item.js"

import { firestore, useFirestoreQuery } from "gatsby-theme-firebase"
import { activeEnv } from "../../utils/helpers"

const AddHarvest = () => {
  const [crops, cropsIsLoading] = useFirestoreQuery(
    firestore.collection(`${activeEnv}-crop`)
  )

  const initialCropSelectValue = { value: "chooseOne" }
  const [cropId, setCropId] = useState(initialCropSelectValue)
  const [choosenCrop, setChoosenCrop] = useState(null)
  const [choosenVariety, setChoosenVariety] = useState(initialCropSelectValue)
  const [weight, setWeight] = useState("")

  const [otherDate, setOtherDate] = useState(false)

  const [year, setYear] = useState(2020)
  const [month, setMonth] = useState("")
  const [day, setDay] = useState("")

  useEffect(() => {
    if (choosenCrop && choosenCrop.Variety.length === 1) {
      setChoosenVariety(choosenCrop.Variety[0])
    }
  }, [choosenCrop])

  const handleAddHarvest = event => {
    event.preventDefault()

    const pickDate = new Date()
    if (otherDate) {
      pickDate.setDate(day)
      pickDate.setMonth(month - 1)
      pickDate.setFullYear(year)
    }

    addItemToFirestore("harvest", {
      Type: choosenCrop.Type,
      Variety: choosenVariety,
      Weight: parseInt(weight),
      Date: pickDate,
    })

    setCropId(initialCropSelectValue)
    setChoosenCrop(null)
    setChoosenVariety(initialCropSelectValue)
    setWeight("")
  }

  return (
    <>
      {cropsIsLoading && <p>Loading crops</p>}
      {crops && (
        <div>
          <form onSubmit={handleAddHarvest}>
            <h2>
              <legend>Record a harvest</legend>
            </h2>

            <p>
              <strong>Date picked: </strong>{" "}
            </p>
            <ul>
              <li>
                <label>
                  {" "}
                  <input
                    type="radio"
                    name="picked-date-radio"
                    defaultChecked
                    onChange={() => {
                      setOtherDate(false)
                    }}
                  />{" "}
                  Today
                </label>
              </li>
              <li>
                <label>
                  {" "}
                  <input
                    type="radio"
                    name="picked-date-radio"
                    onChange={() => {
                      setOtherDate(true)
                    }}
                  />{" "}
                  Choose other date
                </label>
                {otherDate && (
                  <div>
                    <label>
                      Year:
                      <select
                        value={year}
                        onChange={e => {
                          setYear(e.currentTarget.value)
                        }}
                        defaultValue={year}
                      >
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                      </select>
                    </label>
                    <label>
                      Month:
                      <input
                        id="month"
                        value={month}
                        required
                        placeholder="(MM)"
                        pattern="[0-9]*"
                        type="number"
                        minLength="2"
                        maxLength="2"
                        min="01"
                        max="12"
                        onChange={e => {
                          setMonth(e.target.value)
                        }}
                      />
                    </label>
                    <label>
                      Day:{" "}
                      <input
                        id="day"
                        required
                        value={day}
                        placeholder="(DD)"
                        pattern="[0-9]*"
                        type="number"
                        minLength="2"
                        maxLength="2"
                        min="01"
                        max="31"
                        onChange={e => {
                          setDay(e.target.value)
                        }}
                      />
                    </label>
                  </div>
                )}
              </li>
            </ul>

            <div>
              <label>
                <strong>Type:</strong>
                <select
                  value={cropId}
                  onChange={e => {
                    setCropId(e.currentTarget.value)
                    setChoosenCrop(
                      crops.find(crop => crop._id === e.currentTarget.value)
                    )
                  }}
                  defaultValue={cropId.value}
                >
                  <option value="chooseOne">Choose crop type</option>
                  {crops.map(crop => {
                    return (
                      <option key={crop._id} value={crop._id}>
                        {crop.Type}
                      </option>
                    )
                  })}
                </select>
              </label>
            </div>

            {choosenCrop && (
              <div>
                <label>
                  <strong>Variety:</strong>
                  <select
                    value={choosenVariety}
                    onChange={e => {
                      setChoosenVariety(e.currentTarget.value)
                    }}
                    defaultValue={choosenVariety.value}
                    disabled={choosenCrop.Variety.length === 1 ? true : false}
                  >
                    <option value="chooseOne">Choose variety</option>
                    {choosenCrop.Variety.map(variety => {
                      return (
                        <option key={variety} value={variety}>
                          {variety}
                        </option>
                      )
                    })}
                  </select>
                </label>
              </div>
            )}

            {choosenVariety.value !== "chooseOne" && (
              <>
                <div>
                  <label>
                    <strong>Harvest weight in grams:</strong>
                    <input
                      pattern="[0-9]*"
                      type="number"
                      required
                      value={weight}
                      onChange={e => {
                        setWeight(e.currentTarget.value)
                      }}
                    />
                  </label>
                </div>
                <button type="submit">Submit</button>
              </>
            )}
          </form>
        </div>
      )}
    </>
  )
}

export default AddHarvest
