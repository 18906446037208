import { firestore } from "gatsby-theme-firebase"

import { activeEnv } from "./helpers"

export const addItemToFirestore = async (collection, dataToAdd) => {
  console.log(collection, dataToAdd)
  try {
    const docRef = await firestore
      .collection(`${activeEnv}-${collection}`)
      .add({
        ...dataToAdd,
      })
    console.log(docRef)
    console.log("Document written with ID: ", docRef.id)
  } catch (error) {
    console.error("Error adding document: ", error)
  }
}
