import React, { useEffect, useState } from "react"
import { firestore, useFirestoreQuery } from "gatsby-theme-firebase"
import { activeEnv, sumOfArray, convertToKg } from "../../utils/helpers"

const IndividualCropHarvests = () => {
  const [harvests, harvestsIsLoading] = useFirestoreQuery(
    firestore.collection(`${activeEnv}-harvest`)
  )

  const [individualCrops, setIndividualCrops] = useState([])

  useEffect(() => {
    if (harvests.length > 0) {
      const foo = []
      harvests.forEach(crop => {
        // find crop type
        const cropTypeIndex = foo.findIndex(x => x.crop_type === crop.Type)

        if (cropTypeIndex === -1) {
          // if crop type hasn't already been added
          foo.push({
            crop_type: crop.Type,
            total_weight: parseInt(crop.Weight),
            varities: [
              {
                name: crop.Variety,
                picking_weights: [crop.Weight],
              },
            ],
          })
        } else {
          // update total weight
          foo[cropTypeIndex].total_weight =
            foo[cropTypeIndex].total_weight + parseInt(crop.Weight)

          // if crop type HAS previously been added, check if variety has been added
          const cropVarietyIndex = foo[cropTypeIndex].varities.findIndex(
            bar => bar.name === crop.Variety
          )

          if (cropVarietyIndex === -1) {
            // if crop variety hasn't been added to array, add it and the current weight
            foo[cropTypeIndex].varities.push({
              name: crop.Variety,
              picking_weights: [crop.Weight],
            })
          } else {
            // Push this harvest weight to array
            foo[cropTypeIndex].varities[cropVarietyIndex].picking_weights.push(
              crop.Weight
            )
          }
        }
      })

      setIndividualCrops(foo)
    }
  }, [harvests])

  return (
    <>
      {harvestsIsLoading && <p>Loading harvests</p>}
      {harvests && individualCrops && (
        <div>
          <h2>Individual crop data</h2>
          {individualCrops.map(item => {
            const { crop_type, total_weight, varities } = item
            return (
              <details key={crop_type}>
                <summary>{crop_type}</summary>
                <div>
                  <p>
                    <strong>
                      Total weight: {convertToKg(total_weight)} kg
                    </strong>
                  </p>

                  <ul>
                    {varities.map(variety => {
                      const { name, picking_weights } = variety
                      return (
                        <li key={name}>
                          {name}:
                          <ul>
                            <li>
                              {" "}
                              {convertToKg(
                                picking_weights.reduce(sumOfArray)
                              )}{" "}
                              kg picked
                            </li>
                            <li>Picked {picking_weights.length} times </li>
                          </ul>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </details>
            )
          })}
        </div>
      )}
    </>
  )
}

export default IndividualCropHarvests
