import React from "react"

import AddCrop from "./add-crop"
import ListCrops from "./list-crops"

const Crops = () => {
  return (
    <div>
      <ListCrops />
      <AddCrop />
    </div>
  )
}

export default Crops
