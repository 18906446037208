import React from "react"

import { Link } from "gatsby"

import { auth, useAuth } from "gatsby-theme-firebase"

import Crops from "../components/crops"
import Harvests from "../components/harvests"

const App = () => {
  const { isLoggedIn, profile } = useAuth()

  return (
    <div>
      <h1>neildigs.it veg tracker</h1>

      {!isLoggedIn && (
        <p>
          <Link to="/login">login</Link>
        </p>
      )}

      {isLoggedIn && profile && profile.email === "neildakeyne@googlemail.com" && (
        <div>
          <p>Hello {profile.email}</p>
          <button onClick={() => auth.signOut()}>Sign Out</button>
          <hr />
          <Crops />
          <Harvests />
        </div>
      )}
    </div>
  )
}

export default App
