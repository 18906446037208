import React from "react"

import AddHarvest from "./add-harvest"
import ListHarvests from "./list-harvests"
import IndividualCropHarvests from "./individual-crop-details"

const Harvests = () => {
  return (
    <div>
      <hr />
      <AddHarvest />
      <hr />
      <IndividualCropHarvests />
      <hr />
      <ListHarvests />
    </div>
  )
}

export default Harvests
