export const formatNumber = num => {
  // adds comma as speratores for thousands
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export const convertToKg = weight => (weight / 1000).toFixed(2)

export const sumOfArray = (accumulator, currentValue) => {
  return parseInt(accumulator) + parseInt(currentValue)
}

export const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"
