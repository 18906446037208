import React from "react"

import { firestore, useFirestoreQuery } from "gatsby-theme-firebase"
import { activeEnv } from "../../utils/helpers"

const ListCrops = () => {
  const [crops, cropsIsLoading] = useFirestoreQuery(
    firestore.collection(`${activeEnv}-crop`).orderBy("Type", "asc")
  )
  return (
    <>
      {cropsIsLoading && <p>Loading crops</p>}
      {crops && (
        <>
          <h2>Crops</h2>
          <table>
            <thead>
              <tr>
                <td>
                  <strong>Type</strong>
                </td>
                <td>
                  <strong>Varieties</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {crops.map(veg => {
                return (
                  <tr key={veg._id}>
                    <td>{veg.Type}</td>
                    <td>
                      <ul>
                        {veg.Variety.map(variety => {
                          return <li key={variety}>{variety}</li>
                        })}
                      </ul>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  )
}

export default ListCrops
