import React, { useState, useEffect } from "react"

import { formatNumber } from "../../utils/helpers"
import { firestore, useFirestoreQuery } from "gatsby-theme-firebase"
import dayjs from "dayjs"

import { activeEnv, convertToKg, sumOfArray } from "../../utils/helpers"

const ListHarvests = () => {
  const [harvests, harvestsIsLoading] = useFirestoreQuery(
    firestore.collection(`${activeEnv}-harvest`).orderBy("Date", "desc")
  )

  const [totalWeight, setTotalWeight] = useState(null)

  useEffect(() => {
    if (!harvestsIsLoading && harvests.length > 0) {
      setTotalWeight(harvests.map(a => a.Weight).reduce(sumOfArray))
    }
  }, [harvestsIsLoading, harvests])

  return (
    <>
      {harvestsIsLoading && <p>Loading harvests</p>}
      {harvests && (
        <div>
          <h2>All harvests</h2>
          <ul>
            <li>Number of harvests: {harvests.length}</li>
            <li>
              Total weight of harvests:{" "}
              {totalWeight && convertToKg(totalWeight)} kg
            </li>
          </ul>
          <table>
            <thead>
              <tr>
                <td>
                  <strong>Date picked</strong>
                </td>
                <td>
                  <strong>Type</strong>
                </td>
                <td>
                  <strong>Variety</strong>
                </td>
                <td>
                  <strong>Weight</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {harvests.map(harvest => {
                const harvestDate = dayjs(harvest.Date.toDate())
                  .format("YYYY-MM-DD")
                  .toString()

                return (
                  <tr key={harvest._id}>
                    <td>{harvestDate}</td>
                    <td>{harvest.Type}</td>
                    <td>{harvest.Variety}</td>
                    <td>{formatNumber(harvest.Weight)} g</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ListHarvests
